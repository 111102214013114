import React from 'react';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config.json';
import 'react-tooltip/dist/react-tooltip.css'
import './App.css'; // For custom CSS
import MapChart from './Map.js';
import {Tooltip} from "react-tooltip";

const App = () =>
{

    const [plantData, setPlantData] = useState();
    const [alarmData, setAlarmData] = useState();
    const [deviceData, setDeviceData] = useState();
    const [alarmShowId, setAlarmShowId] = useState();
    const [content, setContent] = useState("");


    useEffect(() =>
    {
        fetchData();

        //refresh timer
        const interval = setInterval(() => {
            fetchData();
        }, 60*1000);
        
        return () => {
            clearInterval(interval);
        };

    }, [])

    function fetchData() {
        fetch(config.backend + '/plant', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + config.token,
            },
        })
        .then((response) =>
        {
            if (response.status === 200)
            {
                return response.json()
            }
            /** Simply ignore any errors */
            return undefined
        })
        .then((data) =>
        {
            if (data)
            {
                setPlantData(data);
            }
            else
            {
                setPlantData(undefined);
            }
        })
        .catch((error) =>
        {
            console.log(error);
        })

        fetch(config.backend + '/alarm', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + config.token,
        },
        })
        .then((response) =>
        {
            if (response.status === 200)
            {
                return response.json()
            }
            /** Simply ignore any errors */
            return undefined
        })
        .then((data) =>
        {
            if (data)
            {
                setAlarmData(data);
            }
            else
            {
                setAlarmData(undefined);
            }
        })
        .catch((error) =>
        {
            console.log(error);
        })

        fetch(config.backend + '/device', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + config.token,
            },
            })
            .then((response) =>
            {
                if (response.status === 200)
                {
                    return response.json()
                }
                /** Simply ignore any errors */
                return undefined
            })
            .then((data) =>
            {
                if (data)
                {
                    setDeviceData(data);
                }
                else
                {
                    setDeviceData(undefined);
                }
            })
            .catch((error) =>
            {
                console.log(error);
            })
    }

    function getAlarmsForPlant(aData, plantId)
    {
        let alarms = [];
        for(const a of aData)
        {
            if(a.f_plant === plantId)
            {
                alarms.push(a);
            }
        }
        return alarms;
    }

    function compilePlantWithAlarms(pData, aData) 
    {
        if(pData && aData)
        {
            let ret = pData.slice(0);
            for (let i = 0; i < ret.length; i++)
            {
                let alarms = getAlarmsForPlant(aData, ret[i]['id']);
                ret[i]['alarms'] = alarms;
            }
            return ret;
        }
        else
        {
            return null;
        }
    }

    function getPlantById(plantId) 
    {
        if (plantData)
        {
            for (const element of plantData)
            {
                if(element.id === plantId)
                {
                    return element;
                }
            }
            return null;
        }
        else
        {
            return null;
        }
    }

    function getDeviceByAlarm(alarm)
    {
        for(const device of deviceData)
        {
            //console.log(device);
            if(device.id === alarm.f_device)
            {
                return device;
            }
        }
        //not found
        const fakeDev = {"devname":alarm.device_name, "serial": "<not reported>", "devtype":alarm.device_type};
        return fakeDev;        
    }

    return (
        <Container fluid className="vh-100 d-flex flex-column p-0">
            {/* Header Bar */}
            <Row className="zow-primary text-white p-3">
                <Col>
                    <img src="/logo.png" alt="logo"/>
                </Col>
            </Row>

            {/* Main Content Area with Sidebar and Content */}
            <Row className="flex-grow-1">
                {/* Sidebar */}
                <Col xs={2} className="side-panel text-white p-3 vh-100">
                    { !(plantData && alarmData) && <h4>Loading</h4>}
                    { (plantData && alarmData) && 
                    <div>
                        <h4>Systeem Status</h4>
                        <h5>Systemen Online: <span className='focus'>{Object.keys(plantData).length}</span></h5>
                        <h5>Alarmen: <span className='focus'>{Object.keys(alarmData).length}</span></h5>
                        <ul>
                            { alarmData.map((alarm) => {
                                const plant  = getPlantById(alarm.f_plant);
                                const device = getDeviceByAlarm(alarm);
                                console.log(alarm);
                                return <><li 
                                    key={alarm.id}
                                    >
                                    <a href="javascript:void(0);"
                                    className='hoverlink'
                                    onMouseEnter={() => {console.log("Mouse entered"); setAlarmShowId(alarm.id)}}
                                    onMouseLeave={() => setAlarmShowId(-1)}
                                    >{plant.description}: <span className='focus'>{alarm.name}</span></a>
                                    
                                </li>
                                {(alarmShowId === alarm.id) && (
                                    <div className='hoverdiv'>
                                    <table className='alarmTable'>
                                    <tr><th><h2 className='alarmHeading'>Alarm</h2></th></tr>
                                    <tr><td className='tableSpecifier'>Plant</td><td className='tableValue'>{plant.description}</td></tr>
                                    <tr><td className='tableSpecifier'>Alarm</td><td className='tableValue'>{alarm.name}</td></tr>
                                    <tr><td className='tableSpecifier'>Starttijd</td><td className='tableValue'>{alarm.start_time}</td></tr>
                                    <tr><td className='tableSpecifier'>Omschrijving</td><td className='tableValue'>{alarm.description}</td></tr>
                                    <tr><td className='tableSpecifier'>Code</td><td className='tableValue'>{alarm.alarm_code}</td></tr>
                                    {
                                        (plant.type == "Huawei") &&
                                        (
                                            <>
                                            <tr><td className='tableSpecifier'>Device</td><td className='tableValue'>{device.devname}</td></tr>
                                            <tr><td className='tableSpecifier'>Device Serial</td><td className='tableValue'>{device.serial}</td></tr>
                                            <tr><td className='tableSpecifier'>Device type</td><td className='tableValue'>{device.devtype}</td></tr>
                                            {
                                                (device.invtype) &&
                                                    <tr><td className='tableSpecifier'>Inverter type</td><td className='tableValue'>{device.invtype}</td></tr>
                                            }
                                            </>
                                        )
                                    }
                                    {
                                        (plant.type == "Growatt") &&
                                        (
                                            <>
                                            <tr><td className='tableSpecifier'>Device</td><td className='tableValue'>{alarm.device_name}</td></tr>
                                            <tr><td className='tableSpecifier'>Device Serial</td><td className='tableValue'>{"<not reported>"}</td></tr>
                                            <tr><td className='tableSpecifier'>Device type</td><td className='tableValue'>{alarm.device_type}</td></tr>
                                            </>
                                        )
                                    }
                                    <tr><td className='tableSpecifier'>Reparatie</td><td className='tableValue'>{alarm.repair_suggestion}</td></tr>
                                    <tr><td className='tableSpecifier'>Niveau</td><td className='tableValue'>{alarm.severity}</td></tr>
                                    
                                    </table>
                                    </div>
                                )}
                                </>
                            })}                            
                        </ul>
                    </div>
                    }
                </Col>

                {/* Main Content */}
                <Col className="p-3 main-panel">
                    <MapChart plants={compilePlantWithAlarms(plantData, alarmData)} setTooltipContent={setContent}>
                    </MapChart>
                    <Tooltip id="my-tooltip">{content}</Tooltip>
                </Col>
            </Row>
        </Container>
    );
}

export { App };
