import React from "react"
import { useEffect, useState } from 'react';
import { ComposableMap, Geographies, ZoomableGroup, Geography, Marker } from "react-simple-maps"

const geoUrl =
  "/countries-10m.json"

export default function MapChart({plants, setTooltipContent}) {

  const [markerScale, setMarkerScale] = useState(1);

  function compileMarkers() 
    {
        let markers = [];
        if(plants)
        {
            for(const plant of plants)   
            {
                let color = "#0F0";
                if(plant.alarms.length > 0)
                {
                    color = "#F00";
                }
                let m = { markerOffsetX: plant.markeroffsetx, markerOffsetY: plant.markeroffsety, name: plant.description, coordinates: [plant.longitude, plant.latitude], color: color }
                markers.push(m);
            }
            // console.log("Markers!");
            // console.log(markers)
        }        
        return markers;
    }

  return (
    <ComposableMap projection="geoMercator" projectionConfig={{
        rotate: [-5.0, -51.0, 0],
        scale: 6000
      }}>
      <ZoomableGroup minZoom={0.1} zoom={1} onMove={(event) => {
        console.log(event.zoom);
        if(event.zoom > 1)
        {
          //zooming in
          setMarkerScale(event.zoom);
        }
        else if(event.zoom <= 1)
        {
          //zooming out
          if(markerScale != 1)
          {
            setMarkerScale(1);
          }
        }
      }}>
        <Geographies geography={geoUrl}>
        {({ geographies, outline, borders }) => (
            <>
              <Geography geography={outline} fill="#0088b0" />
              <Geography geography={borders} fill="none" stroke="#D6D6DA" />
            </>
          )}
        </Geographies>
        {compileMarkers().map(({ name, coordinates, markerOffsetX, markerOffsetY, color }) => (
          <Marker key={name} 
          coordinates={coordinates}
          data-tooltip-id="my-tooltip"
          data-tooltip-content={name}
          >
            <circle r={4/markerScale} fill={color} stroke="#fff" strokeWidth={2/markerScale} />
            {/* <text
              textAnchor="middle"
              x={markerOffsetX}
              y={markerOffsetY}
              style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
            >
              {name}
            </text> */}
          </Marker>
        ))}
        </ZoomableGroup>
    </ComposableMap>
  )
}
